<template>
<div>
  <Form :model="formItem" :label-width="100" ref="newsForm">
    <FormItem label="标题" required>
      <Input v-model="formItem.news_title" style="width: 60%"></Input>
    </FormItem>
    <FormItem label="副标题">
      <Input v-model="formItem.news_subtitle" style="width: 60%"></Input>
    </FormItem>
    <FormItem label="简述">
      <Input v-model="formItem.news_desc" style="width: 60%" type="textarea"></Input>
    </FormItem>
    <FormItem label="发布日期" required>
      <DatePicker v-model="news_date" type="date" placeholder="选择日期" style="width: 60%"></DatePicker>
    </FormItem>
    <FormItem label="封面图片">
      <Upload
          name="img"
          ref="upload"
          :show-upload-list="false"
          :default-file-list="defaultList"
          :format="['jpg','jpeg','png']"
          :on-format-error="handleFormatError"
          :before-upload="handleBeforeUpload"
          action="#"
          style="display: inline-block;width:58px;">
        <div style="width: 58px;height:58px;line-height: 58px;text-align: center;background-color: #fff">
          <Icon type="ios-camera" size="20"></Icon>
        </div>
      </Upload>
    </FormItem>
    <FormItem>
      <Row :gutter="10">
        <Col span="6" v-for="(item,index) in defaultList" :key="index" style="position:relative;">
          <div @click="delAttach()" style="position: absolute;right: -5px;top:-5px;width: 20px;height: 20px;background-color: #515a6e;border-radius: 50%;color: #fff;line-height: 20px;text-align: center">X</div>
          <img :src="item.url" alt="" style="width: 100%;height: auto">
        </Col>
      </Row>
    </FormItem>
    <FormItem label="内容" required>
      <teditor style="width: 60%" @inp="onContentInput"></teditor>
    </FormItem>
    <FormItem>
      <Button size="large" type="primary" @click="submit()" :disabled="btnDisabled">确定</Button>
    </FormItem>
  </Form>
</div>
</template>

<script>
import teditor from "../../components/teditor.vue";
import lrz from "lrz";
import api from "../../api/xiaochengxu";
import filter from '../../filter';
export default {
  name: "NewsAdd",
  components: {teditor},
  data: function(){
    return {
      formItem: {
        news_title: '',
        news_subtitle: '',
        news_desc: '',
        news_content: '',
        news_date: '',
        news_img: ''
      },
      defaultList:[],
      news_date: null
    }
  },
  computed: {
    btnDisabled: function(){
      return this.formItem.news_title === '' || this.formItem.news_date === '' || this.formItem.news_content === '';
    }
  },
  watch: {
    news_date: function(){
      if(this.news_date) {
        this.formItem.news_date = filter.formatDate2(this.news_date)
      } else {
        this.news_date = ''
        this.formItem.news_date = ''
      }
    }
  },
  methods: {
    onContentInput: function(e){
      this.formItem.news_content = e
    },
    handleFormatError: function(){
      this.$Message.error('请上传图片格式的文件。');
    },
    delAttach: function(){
      this.formItem.news_img = ''
      this.defaultList = [];
    },
    handleBeforeUpload (file) {
      const that = this;
      lrz(file,{fieldName: 'file'}).then(res =>{
        that.$http.post(api.upload, res.formData).then(response => {
          if(response.data.success) {
            this.formItem.news_img = response.data.path;
            this.defaultList = [];
            this.defaultList.push({
              name: response.data.path,
              url: response.data.path
            })
          } else {
            this.$Message.error(response.data.err);
            console.log(response);
          }
        })
      })
    },
    submit: function(){
      this.$http.post('backend/news/save', this.formItem).then(response => {
        if(response.data.success) {
          this.$Message.success('添加成功')
          this.$router.replace('/xiaochengxu/news')
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    }
  },
  mounted() {
    this.$emit('lastPage', '/xiaochengxu/news')
  },
  destroyed() {
    this.$emit('lastPage', '')
  }
}

</script>

<style scoped>

</style>